<template>
  <div id="app">
      <div class="qianyiApp"><router-view /></div>
      <Web_BaiqueAnyoneChat class="absoluteBottomRight" v-if="anyoneView" v-show="anyoneView % 2" @close="anyoneView++"/>
      <div class="rightButtonPanel" @click="anyoneView++" style="top: calc(50% - 175px)">
          <div class="tips">
              <div class="label">24小时在线咨询服务</div>
              <img class="back" :src="tipsBackground"/>
          </div>
          <img class="clickButton" :src="kefu"/>
      </div>
      <div class="rightButtonPanel" style="top: calc(50% - 101px)">
          <div class="tipSecond">
              <div class="imgBorder">
                <img class="qrcodeImg" :src="qrcodeWeixin"/>
              </div>
              <div class="qrcodelabel">24小时微信咨询服务</div>
              <img class="back" :src="tipsBackground2"/>
          </div>
          <img class="clickButton" :src="weixin"/>

      </div>
      <div class="rightButtonPanel" style="top: calc(50% - 27px)">
          <div class="tips">
              <div class="label">咨询电话 18122393143</div>
              <img class="back" :src="tipsBackground"/>
          </div>
          <img class="clickButton" :src="telephone"/>
      </div>
      <div class="rightButtonPanel " @mouseenter="showTipFirst" style="top: calc(50% + 48px)">
          <div class="tipFirst">
              <div class="label">扫码下载teamCo APP</div>
              <img class="back" :src="tipsBackground"/>
          </div>
          <div class="tipSecond">
              <div class="imgBorder">
                <img class="qrcodeImg" :src="qrcode"/>
              </div>
              <div class="qrcodelabel">扫码下载teamCo APP</div>
              <img class="back" :src="tipsBackground2"/>
          </div>
          <img class="qrcodeButton" :src="qrcode"/>
      </div>
  </div>
</template>

<script>
import QianYiHomePage from './views/QianYiHomePage.vue';
import { Web_BaiqueAnyoneChat } from 'i3v-media-components-center';
import kefu from './assets/icon/kefu.svg';
import weixin from './assets/icon/weixin.svg';
import telephone from './assets/icon/telephone.svg';
import qrcode from './assets/picture/qrcode.png';
import qrcodeWeixin from './assets/picture/qrcodeWeixin.jpg';
import tipsBackground from './assets/icon/tipsBackground.svg';
import tipsBackground2 from './assets/icon/tipsBackground2.svg';

export default {
    components: { QianYiHomePage, Web_BaiqueAnyoneChat },
    data() { return { anyoneView: 0, kefu, weixin, telephone, tipsBackground, qrcode, qrcodeWeixin, tipsBackground2 }},
    mounted() { 
        const tipFirst = document.querySelector('.tipFirst');
        // const tipSecond = document.querySelector('.tipSecond');
        tipFirst.classList.add('animation');

        // tipSecond.classList.add('animation');

        // 8秒后隐藏
        setTimeout(() => {
            tipFirst.classList.remove('animation');
            tipFirst.style.display = 'none';
            // tipSecond.classList.remove('animation');
        }, 8000);
    },

    // 当rightButtonPanel被hover时，同样设置tipFirst为display:none
    methods: {
        showTipFirst() {
            const tipFirst = document.querySelector('.tipFirst');
            tipFirst.style.display = 'none';
        }
    }
}
</script>

<style>
.main {
    margin-top: 75px;
}
.imgPreview-item {
  display: block;
  width: auto;
  margin-top: 8px;
  margin-right: 0px;
  margin-bottom: 8px;
  margin-left: 0px;
  cursor: pointer;
}

.imgPreview-modal>img {
  position: absolute;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  transform: translateZ(0px);
}

body .imgPreview-modal {
  touch-action: none;
  position: fixed;
  z-index: 2001;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  user-select: none;
}

.qianyiApp{
    height: 100vh; width: 100%;
    overflow: hidden auto;
}

.absoluteBottomRight{
    height: 600px; width: 800px;
    position: absolute; bottom: 15px; right: 15px; z-index: 999;
    border-radius: 10px;
}
.rightButtonPanel{
    height: 54px; width: 54px;
    position: absolute; right: 30px;
    cursor: pointer;
    user-select: none;
    display: flex; align-items: center; justify-content: center;
    &:hover { 
        > .tips{ 
            display: flex;
        } 
        > .tipFirst{ 
            display: flex;
        } 
        > .tipSecond{ 
            display: flex;
        } 
    }
    > .tips{ height: 44px; width: 171px; position: absolute; right: 64px; display: none; align-items: center; justify-content: center;
        > .back{ height: 44px; width: 171px; position: absolute; z-index: 1; }
        > .label{ color: white; font-size: 14px; font-weight: 400; z-index: 2 }
    }

    > .tipFirst{ height: 44px; width: 171px; position: absolute; right: 64px; display: none; align-items: center; justify-content: center;
        > .back{ height: 44px; width: 171px; position: absolute; z-index: 1; }
        > .label{ color: white; font-size: 14px; font-weight: 400; z-index: 2 }
    }

    > .tipSecond{ 
        height: 44px; width: 171px; position: absolute; right: 64px; top: -55px; justify-content: center;
        display: none;
        > .back{ height: 165px; width: 171px; position: absolute; z-index: 1; }
        > .label{ color: white; font-size: 14px; font-weight: 400; z-index: 3 }
        > .imgBorder{ 
            height: 100px; width: 100px; background-color: white; display: flex; align-items: center; justify-content: center; z-index: 2; position: absolute; top: 20px;
            > .qrcodeImg{ height: 90px; width: 90px; }
        }
        > .qrcodelabel{ color: white; font-size: 13px; font-weight: 400; z-index: 3; position: absolute; top: 129px; }
    }

    > .animation{ animation: shakeAndHide 8s ease-in-out forwards;display: flex; }
}
.rightButtonPanel .clickButton{
    height: 54px; width: 54px;
    padding: 11px;
    border-radius: 27px;
    border: 2px solid rgba(255, 255, 255, 1);
    background: linear-gradient(180deg, rgba(243, 246, 249, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.rightButtonPanel .qrcodeButton{
    height: 54px; width: 54px;
    padding: 3px;
    border-radius: 6px;
    border: 2px solid rgba(255, 255, 255, 1);
    background: #fff;
}

@keyframes shakeAndHide {
    0% { transform: translateX(0); }
    5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { transform: translateX(-10px); }
    10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { transform: translateX(10px); }
    100% { opacity: 1; }
}
</style>
