<template>
    <div class="TopNav">
        <div class="NavBox">
            <div class="NavBoxleft">
                <img class="handle" src="../assets/picture/logo.svg" @click="ToHome" style="height: 39px;">
                <div class="nav-font nav-fontFirst" v-popover:popover @mouseenter="NavDetailShow = 1"
                    @mouseleave="NavDetailShow = 1">
                    <span class="NavFont" :style="NavDetailShow === 1 ? 'color: rgba(54, 94, 255, 1)' : ''"> 产品功能
                    </span>
                    <img src="../assets/picture/NavSelected.png" class="NavSelect" v-if="NavDetailShow === 1">
                    <!-- <img src="../assets/icon/zankai.png" class="zankai"> -->
                </div>
                <!-- <div class="nav-font">
                    <span> 客户案例 </span>
                    <img src="../assets/icon/zankai.png" class="zankai">
                </div> -->
                <!-- <img src="../assets/icon/zankai.png" class="zankai"> -->
                <div class="nav-font" v-popover:popover @mouseenter="NavDetailShow = 2" @mouseleave="NavDetailShow = 2">
                    <span class="NavFont" :style="NavDetailShow === 2 ? 'color: rgba(54, 94, 255, 1)' : ''"> 服务与支持
                    </span>
                    <img src="../assets/picture/NavSelected.png" class="NavSelect" v-if="NavDetailShow === 2">
                    <!-- style="transition: -webkit-transform 0.5s ease;" :style="NavDetailShow === 2 ? '' : '-webkit-transform: scaleX(0);'" -->
                    <!-- <img src="../assets/icon/zankai.png" class="zankai"> -->
                </div>
                <div class=" nav-font" @click="ToDownloadCenter" @mouseenter="NavDetailShow = 5" @mouseleave="NavDetailShow = 0">
                    <span class="NavFont handle" :style="NavDetailShow === 5 ? 'color: rgba(54, 94, 255, 1)' : ''"> 下载中心 </span>
                    <img src="../assets/picture/NavSelected.png" class="NavSelect" v-if="NavDetailShow === 5">
                    <!-- <img src="../assets/icon/zankai.png" class="zankai"> -->
                </div>
                <!-- <div class="nav-font" v-popover:popover @mouseenter="NavDetailShow = 3" @mouseleave="NavDetailShow = 0">
                    <span class="NavFont" :style="NavDetailShow === 3 ? 'color: rgba(54, 94, 255, 1)' : ''"> 解决方案
                    </span>
                    <img src="../assets/picture/NavSelected.png" class="NavSelect" v-if="NavDetailShow === 3">
                </div> -->
                <!-- <div class="nav-font">
                    <span> 会议 </span> -->
                <!-- <img src="../assets/icon/zankai.png" class="zankai"> -->
                <!-- </div>
                <div class="nav-font">
                    <span> 聊天客服 </span> -->
                <!-- <img src="../assets/icon/zankai.png" class="zankai"> -->
                <!-- </div> -->
                <!-- <div class="nav-font">
                    <span> 定价 </span>
                    <img src="../assets/icon/zankai.png" class="zankai">
                </div> -->
            </div>
            <!-- 添加一个二维码弹窗 -->
            <div class="qrcodeWindow">
                <!-- <el-popover 
                popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;width: 36px; height: 36px;"
                popper-class="qrcodeWindow-style"
                trigger="hover"
                >
                <template #reference>
                    <div class="qrcodeWindow-img"></div>
                </template>
                <template #default>
                    <div
                        class="qrcode-rich-conent"
                    >
                    <img src="../assets/picture/qrcode.png" alt="qrcode" style="width: 100px; height: 100px;">
                    <p>扫码下载teamCo APP</p>
                    </div>
                </template>
                </el-popover> -->
            </div>

            <!-- 注册按钮 -->
            <div class="registerButton"
                onclick='window.open("https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2")'>
                免费注册</div>
            <div class="loginButton" onclick='window.open("https://teamco.com.cn:81/tc/")'>登录</div>
            <div class="NavService" v-if="NavDetailShow === 2" @mouseenter="NavDetailShow = 2"
                @mouseleave="NavDetailShow = 0">
                <!-- <div >教学教程</div> -->
                <div class="NavServicefont" v-for="item in NavServiceList"><router-link :to="item.path" tag="div">
                        {{ item.Title }}</router-link></div>
                <!-- <div class="NavServicefont">联系我们</div> -->
                <!-- <div>更新日志</div> -->
            </div>
        </div>

        <div class="NavDetail" @mouseenter="NavDetailShow = 1" v-if="NavDetailShow === 1"
            @mouseleave="NavDetailShow = 0">
            <div class="BasicBox">
                <div class="BasicBoxleft">
                    <div class="NavDetailselect">
                        <div class="NavDetailTitle">产品功能</div>
                        <template v-for="(item, index) in NavDetailselectList">
                            <div class="selectBox" @mouseenter="Hover = index">
                                <div class="selectBoxtitle"
                                    :style="index === Hover ? 'color: rgba(54, 94, 255, 1)' : 'color: rgba(56, 56, 56, 1);'">
                                    {{ item }}
                                </div>
                                <img src="../assets/icon/Selected.png"
                                    :style="index === Hover ? 'display: inline-block;' : 'display: none;'">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="BasicBoxright">
                    <div class="Basic">
                        <div class="row1" :style="Hover === 3 ? 'height: 525px' : ''">
                            <router-link :to="item.path" tag="div" class="routerBox" v-for="(item, index) in row1" 
                                :key="index" >
                                <div class="routerTitle">
                                    <img :src="item.Imgpath" class="NavIcon">
                                    <div class="OpenLink">
                                        {{ item.routerTitle }}
                                    </div>
                                    <img src="../assets/icon/Selected2.png" class="openImg">
                                </div>
                                <div class="routerDescribe">{{ item.routerDescribe }}</div>
                            </router-link>
                        </div>
                        <div class="row2">
                            <router-link :to="item.path" tag="div" class="routerBox" v-for="(item, index) in row2"
                                :key="index">
                                <div class="routerTitle">
                                    <img :src="item.Imgpath" class="NavIcon">
                                    <div class="OpenLink">
                                        {{ item.routerTitle }}
                                    </div>
                                    <img src="../assets/icon/Selected2.png" class="openImg">
                                </div>
                                <div class="routerDescribe">{{ item.routerDescribe }}</div>
                            </router-link>
                        </div>
                        <div class="row3">
                            <router-link :to="item.path" tag="div" class="routerBox" v-for="(item, index) in row3"
                                :key="index">
                                <div class="routerTitle">
                                    <img :src="item.Imgpath" class="NavIcon">
                                    <div class="OpenLink">
                                        {{ item.routerTitle }}
                                    </div>
                                    <img src="../assets/icon/Selected2.png" class="openImg">
                                </div>
                                <div class="routerDescribe">{{ item.routerDescribe }}</div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="NavSolution" v-if="NavDetailShow === 3" @mouseenter="NavDetailShow = 3"
            @mouseleave="NavDetailShow = 0">
            <div class="SolutionBox">
                <div class="SolutionBoxleft">
                    <div class="NavSolutionselect">
                        <div class="NavSolutionTitle">解决方案</div>
                        <div class="NavSolutiondescribe">
                            针对贵公司已体现出的，或者可以预期的问题、不足、缺陷、需求等等，所提出的一个解决整体问题的方案（建议书、计划表），同时能够确保加以快速有效的执行。
                        </div>
                        <div class="NavContact">
                            <img src="../assets/picture/NavContact.png" class="NavContactImg">
                            <div class="NavContactMsg">
                                <div class="NavContactMsgTitle">定制请咨询</div>
                                <div class="NavContactMsgTime">7X24H</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="SolutionBoxright">
                    <div class="SceneBox">
                        <div class="SolutionRouterTitle">按情景</div>
                        <div class="SceneRouterBox">
                            <router-link class="SceneRouterItem" :to="item.path" tag="div"
                                v-for="(item, index) in Solution" :key="index">
                                <img :src="item.Imgpath">
                                <span class="SceneRouterItemTile handle">{{ item.routerTitle }}</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="IndustryBox">
                        <div class="SolutionRouterTitle">按行业</div>
                        <div class="IndustryRouterBox">
                            <router-link class="IndustryRouterItem" :to="item.path" tag="div"
                                v-for="(item, index) in SolutionIndustry" :key="index"
                                :style="{ backgroundImage: 'url(' + item.Imgpath + ')' }">
                                <div class="IndustryRouterBoxTitle">
                                    <span class="IndustryRouterBoxTitleFont">{{ item.routerTitle }}</span>
                                    <img src="../assets/icon/Solution/ToIcon.png" class="IndustryRouterBoxTitleImg">
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ElementUI from 'element-ui';
import Vue from 'vue';
import { BasicFeatures, ProjectManage, ResearchManage, CRMManage, OrganizationManage, OAApproval, ConferenceSystem, ChatSystem, EnterpriseCloudrives, ManageSystem, Solution, SolutionIndustry } from '../Data/NavDetailData'
Vue.use(ElementUI)

export default {
    data() {
        return {
            Hover: 0, visible: false, NavDetailShow: 0, 
            BasicFeatures, ProjectManage, ResearchManage, CRMManage, OrganizationManage, OAApproval, ConferenceSystem, ChatSystem, EnterpriseCloudrives, ManageSystem,
            Solution, SolutionIndustry,
            NavDetailselectList: ['基础功能', '项目管理', '研发管理', 'CRM管理', '组织管理', 'OA审批', '会议系统', '聊天系统', '企业云盘', '管理后台',],
            row1: BasicFeatures[0], row2: BasicFeatures[1], row3: BasicFeatures[2],
            NavServiceList: [
                {
                    Title: '帮助手册',
                    path: '/HelpBook/1.1'
                },
                {
                    Title: '联系我们',
                    path: '/ContactUs'
                },
            ]
        };
    },
    methods: {
        ToDownloadCenter() {
            this.$router.push('/DownloadCenter');
        },
        ToHome() {
            this.$router.push('/')
        },

    },
    watch: {
        Hover(newVal, oldVal) {
            if (newVal === 0) {
                this.row1 = BasicFeatures[0],
                    this.row2 = BasicFeatures[1],
                    this.row3 = BasicFeatures[2]
            } else if (newVal === 1) {
                this.row1 = ProjectManage[0],
                    this.row2 = ProjectManage[1],
                    this.row3 = ProjectManage[2]
            } else if (newVal === 2) {
                this.row1 = ResearchManage[0],
                    this.row2 = ResearchManage[1],
                    this.row3 = ResearchManage[2]
            } else if (newVal === 3) {
                this.row1 = CRMManage[0],
                    this.row2 = CRMManage[1],
                    this.row3 = CRMManage[2]
            } else if (newVal === 4) {
                this.row1 = OrganizationManage[0],
                    this.row2 = OrganizationManage[1],
                    this.row3 = OrganizationManage[2]
            } else if (newVal === 5) {
                this.row1 = OAApproval[0],
                    this.row2 = OAApproval[1],
                    this.row3 = OAApproval[2]
            } else if (newVal === 6) {
                this.row1 = ConferenceSystem[0],
                    this.row2 = ConferenceSystem[1],
                    this.row3 = ConferenceSystem[2]
            } else if (newVal === 7) {
                this.row1 = ChatSystem[0],
                    this.row2 = ChatSystem[1],
                    this.row3 = ChatSystem[2]
            } else if (newVal === 8) {
                this.row1 = EnterpriseCloudrives[0],
                    this.row2 = EnterpriseCloudrives[1],
                    this.row3 = EnterpriseCloudrives[2]
            } else if (newVal === 9) {
                this.row1 = ManageSystem[0],
                    this.row2 = ManageSystem[1],
                    this.row3 = ManageSystem[2]
            }
        },
        NavDetailShow(newVal, oldVal) {
            const nav = document.querySelector('.TopNav');
            const navTop = nav.offsetTop;
            if (newVal === 0) {
                if (!window.pageYOffset > navTop) {
                    nav.style.backgroundColor = '';
                }

            } else {
                nav.style.backgroundColor = 'rgba(255, 255, 255, 1)';
            }
        }
    },
    mounted() {
        const nav = document.querySelector('.TopNav');
        const navTop = nav.offsetTop;

        window.addEventListener('scroll', () => {

            if (window.pageYOffset > navTop) {
                nav.style.position = 'fixed';
                nav.style.minWidth = 'none';
                nav.style.top = '0px';
                nav.style.backgroundColor = 'rgba(255, 255, 255, 1)';
                nav.style.boxShadow = '0px 6px 7px rgba(217, 217, 217, 0.25)';
                nav.style.zIndex = 2000;
            } else {
                nav.style.top = '';
                nav.style.zIndex = 2000;
                nav.style.boxShadow = '';
                nav.style.minWidth = '1200px';
                nav.style.position = 'relative';
                if (this.NavDetailShow === 0) {
                    nav.style.backgroundColor = '';
                }
            }
        })
    },
}
</script>

<style lang="less">
/* 基本滚动条样式 */
.Basic::-webkit-scrollbar {
    width: 10px;
    display: none;
    /* 宽度 */
}

/* 滚动条轨道 */
.Basic::-webkit-scrollbar-track {
    background: white;
    /* 轨道背景颜色 */
}

/* 滚动条滑块 */
.Basic::-webkit-scrollbar-thumb {
    background: rgb(230, 235, 243);
    /* 滑块颜色 */
    border-radius: 3px;
    /* 圆角 */
}

/* 当滑块被拖动时的颜色 */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

.handle {
    cursor: pointer;
}
.TopNav {
    height: 75px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(239, 247, 255);
    z-index: 200;
    position: fixed; 
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;
    
    .TopNav.scrolled {
        background-color: #fff;
    }
    
    >.NavBox {
        width: 1200px;
        position: relative;
        display: flex;
        align-items: center;

        >.NavBoxleft {
            display: flex;
            align-items: center;
            min-width: 600px;

            >.nav-font {
                margin-left: 39px;
                display: flex;
                align-items: center;
                height: 75px;
                width: 86px;
                flex-direction: column;

                >.NavFont {
                    margin-top: 25px;
                }

                >.NavSelect {
                    margin-top: 6px;
                }
            }

            >.nav-fontFirst {
                margin-left: 80px;
            }
        }

        >.qrcodeWindow{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 18px 0 auto;       
        }

        >.registerButton {
            width: 88px;
            height: 34px;
            opacity: 1;
            color: rgba(54, 94, 255, 1);
            border-radius: 4px;
            border: 1px solid rgba(54, 94, 255, 1);
            background-color: transparent;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 18px 0 0;

            &:hover {
                background-color: #E6EBFF;
            }
        }

        >.loginButton {
            width: 90px;
            height: 36px;
            border-radius: 4px;
            background: rgba(54, 94, 255, 1);
            border: 0px;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: linear-gradient(156.15deg, rgba(54, 94, 255, 1) 0%, rgba(115, 173, 255, 1) 100%);
            }
        }
    }
}


.el-popover.qrcodeWindow-style{
    padding: 0;
    border: none;
    border-radius: 10px;
}

.qrcodeWindow-img{
    width: 36px;
    height: 36px;
    background-image: url(../assets/icon/qrcode.png);
    background-size: 100% 100%;
    cursor: pointer;
}
.qrcodeWindow-img:hover{
    background-image: url(../assets/icon/qrcode2.png);
}

.qrcode-rich-conent{
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    opacity: 1;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1);
}

.NavService {
    width: 96px;
    height: 100px;
    border-radius: 4px;
    box-shadow: 0px 10px 30px rgba(29, 33, 41, 0.06);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    padding: 10px 10px;
    top: 75px;
    left: 342px;

    .NavServicefont {
        color: rgba(78, 89, 105, 1);
        font-size: 14px;
        line-height: 40px;
        font-weight: 400;
        cursor: pointer;
        width: 70px;
        text-align: center;

        &:hover {
            color: rgba(54, 94, 255, 1);
        }
    }
}

.NavDetail {
    width: 100%;
    height: 485px;
    border-radius: 6px;
    background-image: url(../assets/picture/NavBackgroundImg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    box-shadow: 0px 10px 30px rgba(29, 33, 41, 0.06);
    display: flex;
    position: fixed;
    justify-content: center;
    top: 75px;

    >.BasicBox {
        width: 100%;
        width: 1200px;
        display: flex;
        margin: 0 auto;

        >.BasicBoxleft {
            display: flex;
            background-color: rgba(247, 249, 252, 1);
            width: 190px;

            >.NavDetailselect {
                width: 180px;

                >.NavDetailTitle {
                    font-size: 22px;
                    font-weight: 700;
                    margin: 30px 0;
                }

                >.selectBox {
                    height: 25px;
                    display: flex;

                    >img {
                        margin: 5px 0 0 5px;
                        height: 11px;
                    }

                    &:not(:first-child) {
                        margin-top: 14px;
                    }

                    >.selectBoxtitle {
                        font-size: 14px;
                        color: rgba(78, 89, 105, 1);
                    }
                }
            }

        }

        >.BasicBoxright {
            padding-top: 17px;
            padding-left: 20px;
            background-color: white;
            padding-bottom: 30px;

            .Basic {
                display: flex;
                overflow-y: auto;
                width: 1000px;
                height: 465px;

                >.row1 {
                    margin-left: 11px;
                }

                >.row2 {
                    margin-left: 25px;
                }

                >.row3 {
                    margin-left: 25px;
                }
            }

        }

    }

}

.NavSolution {
    width: 100%;
    height: 500px;
    border-radius: 6px;
    background-image: url(../assets/picture/NavBackgroundImg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    box-shadow: 0px 10px 30px rgba(29, 33, 41, 0.06);
    display: flex;
    position: fixed;
    justify-content: center;
    top: 75px;

    >.SolutionBox {
        width: 100%;
        width: 1200px;
        display: flex;
        margin: 0 auto;

        >.SolutionBoxleft {
            display: flex;
            background-color: rgba(247, 249, 252, 1);
            width: 190px;

            >.NavSolutionselect {
                width: 180px;

                >.NavSolutionTitle {
                    font-size: 22px;
                    font-weight: 700;
                    margin: 30px 0 15px 0;
                }

                >.NavSolutiondescribe {
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(99, 99, 99, 1);
                    width: 156px;
                    text-align: justify;
                    margin-bottom: 30px;
                }

                >.NavContact {
                    border: 1px solid rgba(235, 238, 242, 1);
                    display: flex;
                    border-radius: 10px;
                    width: 156px;
                    height: 76px;
                    background: rgba(255, 255, 255, 1);

                    >.NavContactImg {
                        width: 45px;
                        margin: 4px 9px 0 6px;
                    }

                    >.NavContactMsg {
                        margin-top: 21px;

                        >.NavContactMsgTitle {
                            font-size: 14px;
                            font-weight: 500;
                        }

                        >.NavContactMsgTime {
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(140, 140, 140, 1);
                        }
                    }
                }

            }

        }

        >.SolutionBoxright {
            display: flex;
            padding-top: 31px;
            padding-left: 44px;
            background-color: white;
            padding-bottom: 30px;
            width: 980px;

            >.SceneBox {
                width: 447px;

                >.SceneRouterBox {
                    display: flex;
                    flex-wrap: wrap;

                    >.SceneRouterItem {
                        width: 170px;
                        display: flex;
                        margin-top: 18px;
                        cursor: pointer;
                        align-items: center;

                        >.SceneRouterItemTile {
                            font-size: 14px;
                            font-weight: 400;
                            margin-left: 8px;
                            color: rgba(56, 56, 56, 1);
                        }
                    }
                }
            }

            >.IndustryBox {
                >.IndustryRouterBox {
                    margin-top: 8px;
                    display: flex;
                    gap: 22px;
                    flex-wrap: wrap;
                    width: 530px;

                    >.IndustryRouterItem {
                        background-image: url(../assets/picture/Solutionbackground/Solutionbackground1.png);
                        height: 81px;
                        width: 240px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        >.IndustryRouterBoxTitle {


                            >.IndustryRouterBoxTitleFont {
                                margin-left: 16px;
                                font-size: 14px;
                                font-weight: 700;
                                color: white;
                            }

                            >.IndustryRouterBoxTitleImg {
                                margin-left: 6px;
                            }
                        }
                    }
                }

            }
        }
    }
}

.SolutionRouterTitle {
    color: rgba(56, 56, 56, 1);
    font-size: 16px;
    font-weight: 500;
}

.routerBox {
    width: 312px;
    padding-top: 30px;
    padding-right: 12px;
    height: 90px;
    margin-bottom: 12px;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 2px 4px rgba(52, 69, 99, 0.2);
    }

    >.routerTitle {
        font-size: 14px;
        font-weight: 500;
        color: rgba(29, 33, 41, 1);

        display: flex;

        >.OpenLink {
            cursor: pointer;
            margin-left: 13px;
        }

        >.openImg {
            height: 11px;
            margin: 5px 0 0 5px
        }

        >.NavIcon {
            height: 21px;
            margin-left: 38px;
        }
    }

    >.routerDescribe {
        color: rgba(78, 89, 105, 1);
        font-size: 12px;
        font-weight: 400;
        margin-left: 72px;
    }
}
</style>